<template>
  <new-perseu-card title="Homologação para novos terapeutas">
    <template #additionalTitle>
      <div class="ml-5">
        <v-chip v-if="homologData.status" :color="colorStatus" dark>
          {{ homologData.status }}
        </v-chip>
        <v-chip v-else :color="colorStatus">Não enviado</v-chip>
      </div>
    </template>
    <div v-if="accepted">
      <empty-list text="Você é um terapeuta homologado!" />
    </div>
    <div v-else>
      <v-form ref="form">
        <v-card-text>
          <v-row>
            <v-col lg="6" cols="12">
              <v-text-field
                v-model="homologData.trainingSchoolName"
                label="Escola de formação *"
                outlined
                :rules="[$rules.required]"
              />
            </v-col>
            <v-col lg="6" cols="12">
              <v-text-field
                v-model="homologData.yearOfFormation"
                label="Ano de formação *"
                outlined
                :rules="[$rules.required, $rules.year]"
              />
            </v-col>

            <v-col lg="6" cols="12">
              <div class="pa-3 certificate-container">
                <label class="subtitle-1 pa-2">
                  Certificado do curso de biomagnetismo *
                </label>
                <image-expansion
                  ref="imageExpansion"
                  :src="homologData.bioMagCourseCertification"
                  max-width="600"
                />
                <v-file-input
                  v-model="certificateFile"
                  accept=".png, .jpg, .jpeg"
                  show-size
                  label="Fazer upload do arquivo"
                  :rules="[$rules.required]"
                  @change="loadPreview"
                />
              </div>
            </v-col>

            <v-col lg="6" cols="12">
              <v-textarea
                v-model="homologData.therapistObservation"
                rows="4"
                label="Observações"
                clearable
                outlined
                maxlength="500"
                counter
                no-resize
                auto-grow
              />
            </v-col>

            <v-col v-if="homologData.observation" cols="12">
              <v-textarea
                v-model="homologData.observation"
                rows="3"
                label="Resposta da revisão"
                outlined
                readonly
                disabled
                hide-details
                no-resize
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-btn block color="secondary" large @click="submit">
          Enviar dados para homologação
        </v-btn>
      </v-card-actions>
    </div>
  </new-perseu-card>
</template>

<script>
import {
  getData,
  saveData,
} from "@/services/external/therapists-homologation-service";
import {
  awsPutImage,
  getCertificatePreSignedUrl,
} from "@/services/external/aws-service";

export default {
  name: "Homologation",
  components: {
    EmptyList: () => import("@/components/shared/EmptyList"),
  },
  data: () => ({
    homologData: {},
    certificateFile: {},
    keyImageExpansion: new Date().getTime(),
  }),
  computed: {
    colorStatus() {
      const map = new Map([
        ["Recusado", "red"],
        ["Aceito", "green"],
        ["Pendente de revisão", "orange"],
      ]);
      return map.get(this.homologData.status) || "gray";
    },
    accepted() {
      return this.homologData.status === "Aceito";
    },
  },
  created() {
    this.fillData();
  },
  methods: {
    async fillData() {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await getData();
        this.homologData = data;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },

    loadPreview() {
      this.homologData.bioMagCourseCertification = URL.createObjectURL(
        this.certificateFile
      );
    },

    async submit() {
      try {
        this.$store.dispatch("loading/openDialog");
        if (!this.validate()) return;
        const uploadedNewCertificate = this.certificateFile.size > 10;
        if (uploadedNewCertificate) {
          await this.uploadCertificate();
        }
        await this.sendDataToApi();
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },

    async uploadCertificate() {
      const {
        data: { url, key, contentType, resourceUrl },
      } = await getCertificatePreSignedUrl(this.certificateFile.type);

      if (!url) {
        throw new Error("Erro ao fazer upload da imagem");
      }

      await awsPutImage({
        url,
        key,
        contentType,
        fileImage: this.certificateFile,
      });

      this.homologData.bioMagCourseCertification = `${resourceUrl}?time=${new Date().getTime()}`;
    },

    async sendDataToApi() {
      if (!this.$refs.form.validate()) return;
      await saveData(this.homologData);
      await this.fillData();
      this.$toasted.global.success({
        message: "Configurações salvas com sucesso.",
      });
    },
    getFileExtension(fileName) {
      return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
    },
    validate() {
      const oneHundredMbInBytes = 100000000;

      if (!this.$refs.form.validate()) return false;

      if (this.certificateFile.size > oneHundredMbInBytes) {
        this.$toasted.global.error({
          message: "Arquivo de certificado muito grande",
        });
        return false;
      }

      return true;
    },
  },
};
</script>

<style scoped>
.certificate-container {
  border: 1px solid gray;
  border-radius: 5px;
}
</style>
